import { defineMessages } from 'react-intl'

export default defineMessages({
  'statlabel.Account': {
    id: 'app.containers.SwimlaneCheckup.statlabel.Account',
    defaultMessage: 'Accounts',
  },
  'statlabel.Infrastructure': {
    id: 'app.containers.SwimlaneCheckup.statlabel.Infrastructure',
    defaultMessage: 'Infrastructure',
  },
  'statlabel.Data': {
    id: 'app.containers.SwimlaneCheckup.statlabel.Data',
    defaultMessage: 'Data',
  },
  'statlabel.Protection': {
    id: 'app.containers.SwimlaneCheckup.statlabel.Proptection',
    defaultMessage: 'Protections',
  },
  'statlabel.Identity': {
    id: 'app.containers.SwimlaneCheckup.statlabel.Identity',
    defaultMessage: 'Identities',
  },
  enableAll: {
    id: 'app.containers.SwimlaneCheckup.enableAll',
    defaultMessage: 'Enable All Objectives',
  },
  enableAllSwombo: {
    id: 'app.containers.SwimlaneCheckup.enableAllSwombo',
    defaultMessage: 'Enable All Objectives on All Swimlanes',
  },
})
