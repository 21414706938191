import React from 'react'
import ImmutablePropTypes from 'react-immutable-proptypes'
import PropTypes from 'prop-types'
import { List, Map } from 'immutable'
import { compose } from 'redux'
import { Link } from 'react-router-dom'
import qs from 'query-string'
import Popover, { PopoverBody, PopoverAnchor } from 'components/Popover'
import Hoverable from 'components/Hoverable'
import IHelp from 'containers/IHelp'
import Button from 'components/Button'
import ProgressBar from 'components/ProgressBar'
import Icon from 'components/Icon'
import themeable, { themeShape } from 'containers/ThemeManager/Themeable'
import BorderedCard from 'components/BorderedCard'
import { BlockShimmer } from 'components/ShimmerLoader'
import RiskScore from './RiskScore'
import ContentStat from './ContentStat'
import messages from './messages'
import { injectIntl, intlShape } from 'react-intl'
import _ from 'lodash'

import { hasFinishedEvaluatingFrameworks } from 'utils/sonraiUtils'
export class SwimlaneCard extends React.Component {
  constructor(props) {
    super(props)

    this.styles = {
      swimlaneCard: {
        marginBottom: '1em',
      },
      swimlaneTitle: {
        fontSize: '1.3em',
        fontWeight: 400,
      },
      swimlaneBody: {
        display: 'grid',
        gridTemplateColumns: '1fr 225px 2fr',
      },
      swimlaneStats: {
        display: 'grid',
        gridTemplateColumns: '1fr 1fr',
        gridTemplateRows: 'auto auto auto 1fr',
      },
      swimlaneRisk: {
        textAlign: 'center',
      },
      slimButton: {
        padding: '3px 8px',
        marginRight: '0.5em',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        width: 'inherit',
      },
      objectiveWrapper: {
        margin: '0.5em',
        width: '300px',
      },
    }

    const sortedObjectives = this.sortObjectives()
    this.state = {
      disabledObjectives: sortedObjectives.disabledObjectives,
      enabledObjectives: sortedObjectives.enabledObjectives,
    }
  }

  componentDidUpdate(oldProps) {
    if (oldProps.objectivesLoading && !this.props.objectivesLoading) {
      const sortedObjectives = this.sortObjectives()
      this.setState({
        disabledObjectives: sortedObjectives.disabledObjectives,
        enabledObjectives: sortedObjectives.enabledObjectives,
      })
    }
  }

  sortObjectives = () => {
    let disabledObjectives = List()
    let enabledObjectives = List()
    this.props.objectives.forEach(objective => {
      const total = objective.get('definedControlFrameworks', List()).size
      const swimlaneCfsForObjective = objective
        .getIn(['appliedControlFrameworks', 'items'], List())
        .filter(
          config =>
            config &&
            config.get('srn') &&
            (!config.get('swimlaneSRNs') || //null swimlaneSRNs means its applied globally?
              config
                .get('swimlaneSRNs')
                .includes(this.props.swimlane.get('srn')))
        )

      const activeCount = swimlaneCfsForObjective.size

      if (total !== activeCount) {
        disabledObjectives = disabledObjectives.push(objective)
      } else {
        enabledObjectives = enabledObjectives.push(
          objective.set('swimlaneCfsForObjective', swimlaneCfsForObjective)
        )
      }
    })
    return {
      disabledObjectives,
      enabledObjectives,
    }
  }

  enableAllObjectives = objectiveSrns => {
    this.props.showEnableAllObjectiveModal({
      objectiveSrns,
      swimlaneSrn: this.props.swimlane.get('srn'),
    })
  }

  renderStat = nodeType => {
    return (
      <ContentStat
        nodeType={nodeType}
        count={this.props.swimlaneStats.get(nodeType)}
      />
    )
  }

  renderSwimlaneRisk = () => {
    return (
      <RiskScore
        value={this.props.swimlaneRisk || Map()}
        swimlane={this.props.swimlane}
        loading={this.props.swimlaneRisksLoading}
        hasProgressCircle
      />
    )
  }

  renderSwimlaneObjectives = () => {
    if (this.props.objectivesLoading || this.props.cfStatusLoading) {
      return <BlockShimmer />
    }

    return (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          flexWrap: 'wrap',
          overflow: 'auto',
        }}
      >
        {this.state.enabledObjectives.map(objective => {
          const hasEvaluatedFrameworks = hasFinishedEvaluatingFrameworks(
            objective.getIn(['appliedControlFrameworks', 'items'], List()),
            this.props.controlGroups,
            this.props.swimlane.get('srn')
          )

          const total = objective.get('definedControlFrameworks', List()).size
          const swimlaneCfsForObjective = objective.get(
            'swimlaneCfsForObjective'
          )
          const failingCount = swimlaneCfsForObjective.filter(cf =>
            this.props.cfStatus.get(cf.get('srn'))
          ).size

          const succeedingCFs = total - failingCount
          const passPercent = Math.floor((succeedingCFs / total) * 100)
          const loadingMessage = _.isNaN(passPercent)
            ? 'No Control Frameworks in this Objective'
            : hasEvaluatedFrameworks
            ? undefined
            : 'Evaluating Frameworks...'
          return (
            <div
              key={objective.get('srn')}
              style={this.styles.objectiveWrapper}
            >
              <div
                style={{
                  display: 'grid',
                  gridTemplateColumns: '1fr auto',
                }}
              >
                <div
                  style={{
                    fontWeight: 400,
                    margin: '0rem 0rem 0.25rem 0rem',
                  }}
                >
                  {objective.get('name')}
                </div>
                <IHelp info infoMsg={objective.get('description') || ''} />
              </div>
              {!_.isNaN(passPercent) && (
                <Popover trigger="hover" style={{ width: '100%' }}>
                  <PopoverAnchor>
                    <ProgressBar
                      value={passPercent}
                      loadingMessage={loadingMessage}
                    />
                  </PopoverAnchor>

                  <PopoverBody>
                    {hasEvaluatedFrameworks
                      ? `${succeedingCFs} out of ${total} frameworks passing`
                      : 'Evaluating Frameworks...'}
                  </PopoverBody>
                </Popover>
              )}
            </div>
          )
        })}
        {this.state.disabledObjectives.map(objective => {
          if (
            this.props.enablingObjectives.findIndex(
              enablin =>
                enablin.get('objectiveSrn') === objective.get('srn') &&
                enablin.get('swimlaneSrn') === this.props.swimlane.get('srn')
            ) !== -1 ||
            this.props.enablingAllObjectives
          ) {
            return (
              <div style={this.styles.objectiveWrapper}>
                <Button
                  title={objective.get('name')}
                  disabled
                  style={this.styles.slimButton}
                >
                  <Icon
                    fa
                    spin
                    name="sync"
                    style={{ margin: '0rem 0.5rem 0rem 0rem' }}
                  />
                  Enabling {objective.get('name')} Objective
                </Button>
              </div>
            )
          } else {
            return (
              <div
                key={objective.get('srn')}
                style={{
                  ...this.styles.objectiveWrapper,
                  ...{ display: 'flex', alignItems: 'center' },
                }}
              >
                <Button
                  outline
                  title={objective.get('name')}
                  color="primary"
                  style={this.styles.slimButton}
                  onClick={e => {
                    this.props.showEnableObjectiveModal({
                      objectiveSrn: objective.get('srn'),
                      swimlaneSrn: this.props.swimlane.get('srn'),
                    })
                    e.stopPropagation()
                    e.preventDefault()
                  }}
                >
                  Enable {objective.get('name')} Objective
                </Button>
                <IHelp info infoMsg={objective.get('description') || ''} />
              </div>
            )
          }
        })}
      </div>
    )
  }

  render() {
    const swimlane = this.props.swimlane
    return (
      <Link
        to={{
          pathname: '/App/SwimlaneCheckupDetails',
          search: qs.stringify({
            swimlaneSrn: swimlane.get('srn'),
          }),
        }}
        style={{ color: this.props.theme.bodyText, fontWeight: 300 }}
      >
        <Hoverable
          renderContent={({ hovered }) => (
            <BorderedCard
              style={{
                ...this.styles.swimlaneCard,
                backgroundColor: hovered
                  ? this.props.theme.highlight
                  : this.props.theme.light,
              }}
            >
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  padding: '0rem 0rem 0.25rem 0rem',
                }}
              >
                <div style={this.styles.swimlaneTitle}>
                  {swimlane.get('title')}
                </div>
                <Button
                  disabled={
                    this.state.disabledObjectives.isEmpty() ||
                    this.props.objectivesLoading ||
                    this.props.enablingAllObjectives
                  }
                  onClick={e => {
                    this.enableAllObjectives(
                      this.state.disabledObjectives
                        .map(obj => obj.get('srn'))
                        .toJS()
                    )
                    e.stopPropagation()
                    e.preventDefault()
                  }}
                  color="primary"
                  style={this.styles.slimButton}
                >
                  {this.props.intl.formatMessage(messages['enableAll'])}
                </Button>
              </div>

              <div style={this.styles.swimlaneBody}>
                <div style={this.styles.swimlaneStats}>
                  {this.renderStat('Account')}
                  {this.renderStat('Identity')}
                  {this.renderStat('Protection')}
                  {this.renderStat('Infrastructure')}
                  {this.renderStat('Data')}
                </div>
                <div style={this.styles.swimlaneRisk}>
                  {this.renderSwimlaneRisk(swimlane)}
                </div>
                <div style={{ overflow: 'hidden' }}>
                  {this.renderSwimlaneObjectives(swimlane)}
                </div>
              </div>
            </BorderedCard>
          )}
        />
      </Link>
    )
  }
}

SwimlaneCard.propTypes = {
  cfStatus: ImmutablePropTypes.map.isRequired,
  cfStatusLoading: PropTypes.bool,
  enablingObjectives: ImmutablePropTypes.map,
  enablingAllObjectives: PropTypes.bool,
  intl: intlShape,
  showEnableObjectiveModal: PropTypes.func.isRequired,
  showEnableAllObjectiveModal: PropTypes.func.isRequired,
  swimlane: ImmutablePropTypes.map.isRequired,
  swimlaneRisksLoading: PropTypes.bool,
  swimlaneRisk: PropTypes.any,
  swimlaneStats: ImmutablePropTypes.map.isRequired,
  theme: themeShape,
  objectives: ImmutablePropTypes.iterable.isRequired,
  objectivesLoading: PropTypes.bool,
  controlGroups: ImmutablePropTypes.iterable.isRequired,
}

export default compose(injectIntl, themeable)(SwimlaneCard)
