import React from 'react'
import ImmutablePropTypes from 'react-immutable-proptypes'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Map, List } from 'immutable'
import { createStructuredSelector } from 'reselect'
import { compose, bindActionCreators } from 'redux'
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import { FormattedMessage } from 'react-intl'
import messages from './messages'
import { isEnableAllObjectiveSwimlaneDisabled } from 'utils/sonraiUtils'
import Icon from 'components/Icon'
import { PlainCheckbox } from 'components/Checkbox'
import Button from 'components/Button'
import TextLink from 'components/TextLink'
import SearchInput from 'components/SearchInput'
import injectReducer from 'utils/injectReducer'
import injectSaga from 'utils/injectSaga'
import {
  selectSwimlanes,
  selectSwimlanesLoading,
} from 'containers/SonraiData/selectors'
import LoadingAnim from 'components/LoadingAnim'
import {
  selectObjectives,
  selectObjectivesError,
  selectObjectivesLoading,
  selectEnablingObjectives,
  selectEnablingAllObjectivesForSwimlane,
  selectEnablingAllObjectives,
} from 'containers/SonraiData/selectors'
import {
  fetchObjectives,
  enableObjective,
  enableAllObjectives,
  enableAllObjectivesForSwimlane,
} from 'containers/SonraiData/actions'
import Dropdown, {
  DropdownMenu,
  InlineDropdownAnchor,
  DropdownItem,
} from 'components/Dropdown'
import {
  selectControlGroups,
  selectLoadedControlGroups,
} from 'containers/ControlFrameworkData/selectors'
import reducer from './reducer'
import {
  selectSwimlaneStats,
  selectCfStatus,
  selectCfStatusLoading,
  selectSwimlaneRisks,
  selectSwimlaneRisksLoading,
} from './selectors'
import {
  fetchSwimlaneStats,
  fetchCfStatus,
  fetchSwimlaneRisks,
} from './actions'
import sagas from './sagas'

import SwimlaneCard from './SwimlaneCard'

const ORDERBY = {
  RISK: 'RISK',
  TITLE: 'TITLE',
}
export class SwimlaneCheckup extends React.Component {
  constructor(props) {
    super(props)

    this.styles = {
      container: {
        padding: '1em',
        width: '100%',
        height: '100%',
        display: 'grid',
        gridTemplateRows: 'auto 1fr',
      },
      title: {
        display: 'grid',
        gridTemplateColumns: '1fr auto',
        padding: '0rem 0rem 0.5rem 0rem',
      },
      body: {
        overflow: 'auto',
      },
      header: {
        marginBottom: '1em',
      },
      filters: {
        display: 'grid',
        gridTemplateColumns: '1fr auto auto',
        gridColumnGap: '1em',
        alignItems: 'center',
      },
    }

    this.state = {
      swimlaneFilter: '',
      orderBy: ORDERBY.RISK,
      enableObjectiveModal: null,
      enableAllObjectiveModal: null,
      allowRemediations: false,
      enableAllObjSwimlaneModal: false,
    }

    props.fetchSwimlaneStats()
    props.fetchObjectives()
    props.fetchCfStatus()
    props.fetchSwimlaneRisks()
  }

  getFilteredSwimanes = () => {
    let swimlanes = this.props.swimlanes.toList()
    if (this.state.swimlaneFilter.trim()) {
      const searchTerm = this.state.swimlaneFilter.trim().toLowerCase()

      swimlanes = this.props.swimlanes.toList().filter(swimlane => {
        const title = (swimlane.get('title') || '').toLowerCase()

        return title.includes(searchTerm)
      })
    }

    if (this.state.orderBy === ORDERBY.RISK) {
      return swimlanes
        .sortBy(swimlane => {
          const risk = this.props.swimlaneRisks.get(swimlane.get('srn'))
          return risk ? risk : 'A'
        })
        .reverse()
    }

    return swimlanes.sortBy(swimlane => {
      return (swimlane.get('title') || '').toLowerCase()
    })
  }

  setSearchFilter = e => {
    this.setState({
      swimlaneFilter: e.target.value,
    })
  }

  setAllowRemediations = value => {
    this.setState({
      allowRemediations: value,
    })
  }

  showEnableObjectiveModal = params => {
    this.setState({
      enableObjectiveModal: params,
      allowRemediations: false,
    })
  }

  showEnableAllObjectiveModal = params => {
    this.setState({
      enableAllObjectiveModal: params,
      allowRemediations: false,
    })
  }

  showEnableAllObjSwimlaneModal = () => {
    this.setState({
      enableAllObjSwimlaneModal: true,
      allowRemediations: false,
    })
  }

  cancelEnableModal = () => {
    this.setState({
      enableObjectiveModal: null,
      allowRemediations: false,
    })
  }

  cancelEnableAllModal = () => {
    this.setState({
      enableAllObjectiveModal: null,
      allowRemediations: false,
    })
  }

  cancelEnableAllObjSwimlaneModal = () => {
    this.setState({
      enableAllObjSwimlaneModal: false,
      allowRemediations: false,
    })
  }

  enableAllObjectivesForSwimlane = () => {
    this.props.enableAllObjectivesForSwimlane({
      ...this.state.enableAllObjectiveModal,
      enableEscalationSchemes: this.state.allowRemediations,
    })

    this.setState({
      enableAllObjectiveModal: null,
      allowRemediations: false,
    })
  }

  enableObjective = () => {
    this.props.enableObjective({
      ...this.state.enableObjectiveModal,
      enableEscalationSchemes: this.state.allowRemediations,
    })

    this.setState({
      enableObjectiveModal: null,
      allowRemediations: false,
    })
  }

  setOrder = ({ value }) => {
    this.setState({
      orderBy: value,
    })
  }

  renderSwimlane = swimlane => {
    const objectivesWithCFs = this.props.objectives.filter(
      obj => !obj.get('definedControlFrameworks', List()).isEmpty()
    )

    return (
      <SwimlaneCard
        swimlane={swimlane}
        swimlaneStats={
          this.props.swimlaneStats.get(swimlane.get('srn')) || Map()
        }
        swimlaneRisksLoading={this.props.swimlaneRisksLoading}
        swimlaneRisk={this.props.swimlaneRisks.get(swimlane.get('srn'))}
        objectivesLoading={this.props.objectivesLoading}
        objectives={objectivesWithCFs}
        enablingObjectives={this.props.enablingObjectives}
        enablingAllObjectives={
          this.props.enablingAllObjectives ||
          this.props.enablingAllObjectivesForSwimlane === swimlane.get('srn')
        }
        cfStatus={this.props.cfStatus.get(swimlane.get('srn')) || Map()}
        cfStatusLoading={this.props.cfStatusLoading}
        showEnableObjectiveModal={this.showEnableObjectiveModal}
        showEnableAllObjectiveModal={this.showEnableAllObjectiveModal}
        controlGroups={this.props.controlGroups}
      />
    )
  }

  renderConfirmRemediationBotsModal = () => {
    return (
      <Modal isOpen={true} toggle={this.cancelEnableModal}>
        <ModalHeader toggle={this.cancelEnableModal}>
          Enable escalations for this objective?
        </ModalHeader>
        <ModalBody>
          <p>
            Some policies included in objectives have default escalation schemes
            that automatically assign a ticket to a remediation bot.{' '}
            <strong>Remediation bots make changes in your cloud</strong> to
            automatically resolve problems.{' '}
          </p>
          <PlainCheckbox
            checked={this.state.allowRemediations}
            onChange={this.setAllowRemediations}
          />{' '}
          Accept default escalation schemes
        </ModalBody>
        <ModalFooter>
          <TextLink onClick={this.cancelEnableModal}>Cancel</TextLink>
          <Button color="primary" onClick={this.enableObjective}>
            Enable Objective
          </Button>
        </ModalFooter>
      </Modal>
    )
  }

  renderConfirmAllRemediationBotsModal = () => {
    return (
      <Modal isOpen={true} toggle={this.cancelEnableAllModal}>
        <ModalHeader toggle={this.cancelEnableAllModal}>
          Enable escalations for <b>all</b> objectives?
        </ModalHeader>
        <ModalBody>
          <p>
            Some policies included in objectives have default escalation schemes
            that automatically assign a ticket to a remediation bot.{' '}
            <strong>Remediation bots make changes in your cloud</strong> to
            automatically resolve problems.{' '}
          </p>
          <PlainCheckbox
            checked={this.state.allowRemediations}
            onChange={this.setAllowRemediations}
          />{' '}
          Accept default escalation schemes
        </ModalBody>
        <ModalFooter>
          <TextLink onClick={this.cancelEnableAllModal}>Cancel</TextLink>
          <Button color="primary" onClick={this.enableAllObjectivesForSwimlane}>
            Enable <b>All</b> Objectives
          </Button>
        </ModalFooter>
      </Modal>
    )
  }

  renderConfirmAllSwomboRemediationBotsModal = () => {
    return (
      <Modal isOpen={true} toggle={this.cancelEnableAllObjSwimlaneModal}>
        <ModalHeader toggle={this.cancelEnableAllObjSwimlaneModal}>
          Enable escalations for <b>all</b> objectives on <b>all</b> swimlanes?
        </ModalHeader>
        <ModalBody>
          <p>
            Some policies included in objectives have default escalation schemes
            that automatically assign a ticket to a remediation bot.{' '}
            <strong>Remediation bots make changes in your cloud</strong> to
            automatically resolve problems.{' '}
          </p>
          <PlainCheckbox
            checked={this.state.allowRemediations}
            onChange={this.setAllowRemediations}
          />{' '}
          Accept default escalation schemes
        </ModalBody>
        <ModalFooter>
          <TextLink onClick={this.cancelEnableAllObjSwimlaneModal}>
            Cancel
          </TextLink>
          <Button color="primary" onClick={this.enableAllObjectives}>
            Enable <b>All</b> Objectives
          </Button>
        </ModalFooter>
      </Modal>
    )
  }

  enableAllObjectives = () => {
    this.props.enableAllObjectives({
      enableEscalationSchemes: this.state.allowRemediations,
    })
    this.setState({
      enableAllObjSwimlaneModal: false,
      allowRemediations: false,
    })
  }

  render() {
    if (this.props.swimlanesLoading || !this.props.loadedControlGroups) {
      return <LoadingAnim />
    }

    const isDisabled = isEnableAllObjectiveSwimlaneDisabled(
      this.props.objectives,
      this.props.controlGroups
    )

    return (
      <div style={this.styles.container}>
        {this.state.enableObjectiveModal &&
          this.renderConfirmRemediationBotsModal()}
        {this.state.enableAllObjectiveModal &&
          this.renderConfirmAllRemediationBotsModal()}
        {this.state.enableAllObjSwimlaneModal &&
          this.renderConfirmAllSwomboRemediationBotsModal()}
        <div style={this.styles.header}>
          <div style={this.styles.title}>
            <div style={{ fontSize: '1.5em' }}>All Swimlanes</div>
            <Button
              color="primary"
              onClick={this.showEnableAllObjSwimlaneModal}
              style={{ marginBottom: '0.25em' }}
              disabled={
                isDisabled ||
                this.props.enablingAllObjectives ||
                this.props.objectivesLoading
              }
            >
              {this.props.enablingAllObjectives ? (
                <span style={{ display: 'block', width: '150px' }}>
                  <Icon fa name="sync" spin />
                </span>
              ) : (
                <FormattedMessage {...messages.enableAllSwombo} />
              )}
            </Button>
          </div>
          <div style={this.styles.filters}>
            <SearchInput
              onChange={this.setSearchFilter}
              placeholder="Search Swimlanes..."
              value={this.state.swimlaneFilter}
            />
            <div>
              Sort By:{' '}
              <Dropdown
                selectedValue={this.state.orderBy}
                onClick={this.setOrder}
              >
                <InlineDropdownAnchor />
                <DropdownMenu>
                  <DropdownItem label="Risk Grade" value={ORDERBY.RISK} />
                  <DropdownItem label="Swimlane Title" value={ORDERBY.TITLE} />
                </DropdownMenu>
              </Dropdown>
            </div>
          </div>
        </div>
        <div style={this.styles.body}>
          {this.getFilteredSwimanes().map(this.renderSwimlane)}
        </div>
      </div>
    )
  }
}

SwimlaneCheckup.propTypes = {
  cfStatus: ImmutablePropTypes.map.isRequired,
  cfStatusLoading: PropTypes.bool,
  enablingObjectives: ImmutablePropTypes.list,
  enablingAllObjectives: PropTypes.bool,
  enablingAllObjectivesForSwimlane: PropTypes.string,
  enableObjective: PropTypes.func,
  enableAllObjectives: PropTypes.func,
  enableAllObjectivesForSwimlane: PropTypes.func,
  fetchSwimlaneStats: PropTypes.func.isRequired,
  fetchObjectives: PropTypes.func.isRequired,
  fetchCfStatus: PropTypes.func.isRequired,
  fetchSwimlaneRisks: PropTypes.func.isRequired,
  swimlanes: ImmutablePropTypes.iterable.isRequired,
  swimlanesLoading: PropTypes.bool,
  swimlaneRisks: ImmutablePropTypes.map.isRequired,
  swimlaneRisksLoading: PropTypes.bool,
  swimlaneStats: ImmutablePropTypes.map.isRequired,
  objectives: ImmutablePropTypes.iterable.isRequired,
  objectivesLoading: PropTypes.bool,
  controlGroups: ImmutablePropTypes.iterable.isRequired,
  loadedControlGroups: PropTypes.bool,
}

const mapStateToProps = createStructuredSelector({
  cfStatus: selectCfStatus,
  cfStatusLoading: selectCfStatusLoading,
  enablingObjectives: selectEnablingObjectives,
  enablingAllObjectives: selectEnablingAllObjectives,
  enablingAllObjectivesForSwimlane: selectEnablingAllObjectivesForSwimlane,
  objectives: selectObjectives,
  objectivesError: selectObjectivesError,
  objectivesLoading: selectObjectivesLoading,
  swimlanes: selectSwimlanes,
  swimlanesLoading: selectSwimlanesLoading,
  swimlaneRisks: selectSwimlaneRisks,
  swimlaneRisksLoading: selectSwimlaneRisksLoading,
  swimlaneStats: selectSwimlaneStats,
  controlGroups: selectControlGroups,
  loadedControlGroups: selectLoadedControlGroups,
})

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      fetchSwimlaneStats,
      fetchObjectives,
      enableObjective,
      enableAllObjectives,
      enableAllObjectivesForSwimlane,
      fetchCfStatus,
      fetchSwimlaneRisks,
    },
    dispatch
  )
}

const withConnect = connect(mapStateToProps, mapDispatchToProps)
const withReducer = injectReducer({ key: 'swimlaneCheckup', reducer })
const withSaga = injectSaga({ key: 'swimlaneCheckup', saga: sagas })

export default compose(withReducer, withConnect, withSaga)(SwimlaneCheckup)
