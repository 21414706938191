import { fromJS } from 'immutable'
import { handleActions } from 'redux-actions'

import {
  FETCH_SWIMLANE_STATS,
  SET_SWIMLANE_STATS,
  FETCH_SWIMLANE_STATS_ERROR,
  FETCH_CF_STATUS,
  FETCH_CF_STATUS_ERROR,
  SET_CF_STATUS,
  FETCH_SWIMLANE_RISKS_ERROR,
  FETCH_SWIMLANE_RISKS,
  SET_SWIMLANE_RISKS,
} from './constants'

const initialState = fromJS({
  swimlaneStats: {},
  swimlaneStatsLoading: false,
  cfStatus: {},
  cfStatusLoading: false,
  swimlaneRisks: {},
  swimlaneRisksLoading: false,
})

const swimlaneManagerReducer = handleActions(
  {
    [FETCH_SWIMLANE_STATS]: state => state.set('swimlaneStatsLoading', true),
    [FETCH_SWIMLANE_STATS_ERROR]: state =>
      state.set('swimlaneStatsLoading', false),
    [SET_SWIMLANE_STATS]: (state, { payload }) =>
      state
        .set('swimlaneStats', fromJS(payload))
        .set('swimlaneStatsLoading', false),
    [FETCH_CF_STATUS]: state => state.set('cfStatusLoading', true),
    [FETCH_CF_STATUS_ERROR]: state => state.set('cfStatusLoading', false),
    [SET_CF_STATUS]: (state, { payload }) =>
      state.set('cfStatusLoading', false).set('cfStatus', fromJS(payload)),
    [FETCH_SWIMLANE_RISKS]: state => state.set('swimlaneRisksLoading', true),
    [FETCH_SWIMLANE_RISKS_ERROR]: state =>
      state.set('swimlaneRisksLoading', false),
    [SET_SWIMLANE_RISKS]: (state, { payload }) =>
      state
        .set('swimlaneRisksLoading', false)
        .set('swimlaneRisks', fromJS(payload)),
  },
  initialState
)

export default swimlaneManagerReducer
