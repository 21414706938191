import { all, put, takeLatest, select } from 'redux-saga/effects'
import gql from 'graphql-tag'
import _ from 'lodash'
import { getClient } from 'apolloClient'

import { TICKET_STATUS } from 'appConstants'
import { selectQueryTypes } from 'containers/SonraiData/selectors'
import { getInterfaceCount } from 'utils/sonraiUtils'

import {
  setSwimlaneStats,
  fetchSwimlaneStatsError,
  setCfStatus,
  fetchCfStatusError,
  setSwimlaneRisks,
  fetchSwimlaneRisksError,
} from './actions'
import {
  FETCH_SWIMLANE_STATS,
  FETCH_CF_STATUS,
  FETCH_SWIMLANE_RISKS,
} from './constants'

function* fetchSwimlaneStats() {
  try {
    const client = getClient()
    const results = yield client.query({
      query: gql`
        query getStatsBySwimlane {
          GroupedQuery(where: { keys: [Swimlane, Label] }) {
            key
            items {
              value
              hasGroupedResult {
                items {
                  value
                  count
                }
              }
            }
          }
        }
      `,
    })

    if (results.errors) {
      throw new Error('Error fetching stats for swimlanes')
    }
    const queryTypes = yield select(selectQueryTypes)

    const swimlaneMap = {}
    const stats = results.data.GroupedQuery.items
    stats.forEach(swimlaneStat => {
      const countsByType = {}
      swimlaneStat.hasGroupedResult.items.forEach(typecount => {
        countsByType[typecount.value] = typecount.count
      })

      //calculate counts for the interface types
      countsByType['Identity'] = getInterfaceCount(
        queryTypes,
        'Identity',
        countsByType
      )
      countsByType['Data'] = getInterfaceCount(queryTypes, 'Data', countsByType)
      countsByType['Protection'] = getInterfaceCount(
        queryTypes,
        'Protection',
        countsByType
      )
      countsByType['Infrastructure'] = getInterfaceCount(
        queryTypes,
        'Infrastructure',
        countsByType
      )

      swimlaneMap[swimlaneStat.value] = countsByType
    })

    yield put(setSwimlaneStats(swimlaneMap))
  } catch (e) {
    //eslint-disable-next-line no-console
    console.error('error fetching stats for swimlanes', e)
    yield put(fetchSwimlaneStatsError())
  }
}

function* fetchCfStatus() {
  try {
    const client = getClient()
    const results = yield client.query({
      query: gql`
        query getCFTicketsBySwimlane {
          TicketRollups(
            where: {
              resourceSRN: { value: "ALL" }
              resourceType: { value: "ALL" }
              resourceLabel: { value: "ALL" }
              actionClassification: { value: "ALL" }
              severityCategory: { value: "ALL" }
              ticketKey: { op: NEQ, value: "ALL" }
              ticketType: { value: "Framework" }
              orgName: { value: "ALL" }
              account: { value: "ALL" }
              status: { op: IN_LIST, values: ["${TICKET_STATUS.NEW}", "${TICKET_STATUS.IN_PROGRESS}"] }
            }
          ) {
            items {
              ticketKey
              count
              swimlaneSrns
            }
          }
        }
      `,
    })

    const rollups = _.get(results, ['data', 'TicketRollups', 'items'])

    if (results.errors || !rollups) {
      throw new Error('Error fetching stats for swimlanes')
    }

    const swimlaneCfsHasTickets = {}
    rollups.forEach(rollup => {
      const swimlaneId = rollup.swimlaneSrns[0]
      const frameworkId = rollup.ticketKey

      if (rollup.count > 0) {
        if (swimlaneCfsHasTickets[swimlaneId]) {
          swimlaneCfsHasTickets[swimlaneId][frameworkId] = true
        } else {
          swimlaneCfsHasTickets[swimlaneId] = {
            [frameworkId]: true,
          }
        }
      }
    })

    yield put(setCfStatus(swimlaneCfsHasTickets))
  } catch (e) {
    //eslint-disable-next-line no-console
    console.error('error fetching the ticket status for swimlanes', e)
    yield put(fetchCfStatusError())
  }
}

function* fetchSwimlaneRisks() {
  try {
    const client = getClient()
    const results = yield client.query({
      query: gql`
        query getSwimlaneRisks {
          TicketRollups(
            where: {
              resourceSRN: { value: "ALL" }
              resourceType: { value: "ALL" }
              resourceLabel: { value: "ALL" }
              actionClassification: { value: "ALL" }
              severityCategory: { value: "ALL" }
              ticketKey: { value: "ALL" }
              ticketType: { value: "ALL" }
              orgName: { value: "ALL" }
              account: { value: "ALL" }
              status: { op: IN_LIST, values: ["${TICKET_STATUS.NEW}", "${TICKET_STATUS.IN_PROGRESS}"] }
            }
          ) {
            items {
              swimlaneSrns
              riskScore
              riskScoreNumeric
            }
          }
        }
      `,
    })

    const rollups = _.get(results, ['data', 'TicketRollups', 'items'])

    if (results.errors || !rollups) {
      throw new Error('Error fetching risk scores for swimlanes')
    }

    const swimlaneRiskScores = {}
    rollups.forEach(rollup => {
      const { riskScore, riskScoreNumeric, swimlaneSrns } = rollup
      const swimlaneId = _.first(swimlaneSrns)
      swimlaneRiskScores[swimlaneId] = {
        riskScore,
        riskScoreNumeric,
      }
    })

    yield put(setSwimlaneRisks(swimlaneRiskScores))
  } catch (e) {
    //eslint-disable-next-line no-console
    console.error('error fetching the ticket status for swimlanes', e)
    yield put(fetchSwimlaneRisksError())
  }
}

function* swimlaneCheckupSaga() {
  yield all([
    takeLatest(FETCH_SWIMLANE_STATS, fetchSwimlaneStats),
    takeLatest(FETCH_CF_STATUS, fetchCfStatus),
    takeLatest(FETCH_SWIMLANE_RISKS, fetchSwimlaneRisks),
  ])
}

export default swimlaneCheckupSaga
