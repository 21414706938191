import {
  FETCH_SWIMLANE_STATS,
  SET_SWIMLANE_STATS,
  FETCH_SWIMLANE_STATS_ERROR,
  FETCH_CF_STATUS,
  FETCH_CF_STATUS_ERROR,
  SET_CF_STATUS,
  FETCH_SWIMLANE_RISKS_ERROR,
  FETCH_SWIMLANE_RISKS,
  SET_SWIMLANE_RISKS,
} from './constants'

import { createAction } from 'redux-actions'

export const fetchSwimlaneStats = createAction(FETCH_SWIMLANE_STATS)
export const setSwimlaneStats = createAction(SET_SWIMLANE_STATS)
export const fetchSwimlaneStatsError = createAction(FETCH_SWIMLANE_STATS_ERROR)

export const fetchCfStatus = createAction(FETCH_CF_STATUS)
export const fetchCfStatusError = createAction(FETCH_CF_STATUS_ERROR)
export const setCfStatus = createAction(SET_CF_STATUS)

export const fetchSwimlaneRisks = createAction(FETCH_SWIMLANE_RISKS)
export const fetchSwimlaneRisksError = createAction(FETCH_SWIMLANE_RISKS_ERROR)
export const setSwimlaneRisks = createAction(SET_SWIMLANE_RISKS)
