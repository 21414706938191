import React from 'react'
import PropTypes from 'prop-types'
import {
  CircularProgressbarWithChildren,
  buildStyles,
} from 'react-circular-progressbar'
import 'react-circular-progressbar/dist/styles.css'

const ProgressCircle = props => {
  const { value, pathColor } = props
  return (
    <CircularProgressbarWithChildren
      value={value}
      text={props.grade}
      styles={buildStyles({
        pathColor,
        trailColor: '#fff',
        textColor: pathColor,
        textSize: '2.3rem',
      })}
    >
      <div>
        <div
          style={{
            margin: '4rem 0rem 0rem 0rem',
            fontSize: '0.9rem',
          }}
        >
          Risk Grade
        </div>
      </div>
    </CircularProgressbarWithChildren>
  )
}

ProgressCircle.propTypes = {
  grade: PropTypes.string,
  pathColor: PropTypes.string,
  value: PropTypes.number,
}

export default ProgressCircle
