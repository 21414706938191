const prefix = 'app/SwimlaneCheckup/'
export const FETCH_SWIMLANE_STATS = prefix + 'FETCH_SWIMLANE_STATS'
export const SET_SWIMLANE_STATS = prefix + 'SET_SWIMLANE_STATS'
export const FETCH_SWIMLANE_STATS_ERROR = prefix + 'FETCH_SWIMLANE_STATS_ERROR'
export const FETCH_CF_STATUS = prefix + 'FETCH_CF_STATUS'
export const SET_CF_STATUS = prefix + 'SET_CF_STATUS'
export const FETCH_CF_STATUS_ERROR = prefix + 'FETCH_CF_STATUS_ERROR'
export const FETCH_SWIMLANE_RISKS = prefix + 'FETCH_SWIMLANE_RISK'
export const SET_SWIMLANE_RISKS = prefix + 'SET_SWIMLANE_RISKS'
export const FETCH_SWIMLANE_RISKS_ERROR = prefix + 'FETCH_SWIMLANE_RISKS_ERROR'
