import React from 'react'
import PropTypes from 'prop-types'
import { scaleLinear } from 'd3-scale'
import color from 'color'
import _ from 'lodash'
import themeable, { themeShape } from 'containers/ThemeManager/Themeable'

const ProgressBar = ({ value, theme, loadingMessage }) => {
  const colorRange = scaleLinear()
    .domain([0, 100])
    .range([theme.primary, theme.success])

  const fillColor = color(colorRange(value)).rgb().string()

  const styles = {
    container: {
      height: '20px',
      width: '100%',
      backgroundColor: theme.neutralLight,
    },
    fill: {
      height: '100%',
      width: `${value}%`,
      backgroundColor: fillColor,
      textAlign: 'right',
    },
    label: {
      padding: '5px',
      color: value > 2 ? theme.light : theme.bodyText,
      fontWeight: 'bold',
    },
  }

  if (loadingMessage) {
    return (
      <div style={styles.container}>
        <div style={{ backgroundColor: theme.neutralLight }}>
          <span
            style={{
              padding: '5px',
              color: theme.neutralDark,
            }}
          >
            {loadingMessage}
          </span>
        </div>
      </div>
    )
  }

  return (
    <div style={styles.container}>
      <div style={styles.fill}>
        <span style={styles.label}>{`${value}%`}</span>
      </div>
    </div>
  )
}

ProgressBar.propTypes = {
  value: PropTypes.number,
  theme: themeShape,
  loadingMessage: PropTypes.string,
}

export default themeable(ProgressBar)
