import React from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'

import themeable from 'containers/ThemeManager/Themeable'
import Icon from 'components/Icon'
import { getIconMapping } from 'utils/sonraiUtils'
import messages from './messages'

const ContentStat = ({ nodeType, count, typeColors }) => {
  const lowercase = nodeType.toLowerCase()

  return (
    <div style={{ margin: '0.5em 0' }}>
      <Icon
        fa
        name={getIconMapping(nodeType).faIcon}
        color={typeColors[lowercase]}
      ></Icon>
      &nbsp;
      {count ? count : '0'}
      &nbsp; <FormattedMessage {...messages[`statlabel.${nodeType}`]} />
    </div>
  )
}

ContentStat.propTypes = {
  typeColors: PropTypes.object,
  count: PropTypes.number,
  nodeType: PropTypes.string,
}

export default themeable(ContentStat)
