import { createSelector } from 'reselect'
import { Map } from 'immutable'

const selectSwimlaneCheckupDomain = state =>
  state.get('swimlaneCheckup') || Map()

export const selectSwimlaneStatsLoading = createSelector(
  selectSwimlaneCheckupDomain,
  swimlaneDomain => swimlaneDomain.get('swimlaneStatsLoading')
)

export const selectSwimlaneStats = createSelector(
  selectSwimlaneCheckupDomain,
  swimlaneDomain => swimlaneDomain.get('swimlaneStats') || Map()
)

export const selectCfStatusLoading = createSelector(
  selectSwimlaneCheckupDomain,
  swimlaneDomain => swimlaneDomain.get('cfStatusLoading')
)

export const selectCfStatus = createSelector(
  selectSwimlaneCheckupDomain,
  swimlaneDomain => swimlaneDomain.get('cfStatus') || Map()
)

export const selectSwimlaneRisksLoading = createSelector(
  selectSwimlaneCheckupDomain,
  swimlaneDomain => swimlaneDomain.get('swimlaneRisksLoading')
)

export const selectSwimlaneRisks = createSelector(
  selectSwimlaneCheckupDomain,
  swimlaneDomain => swimlaneDomain.get('swimlaneRisks') || Map()
)
