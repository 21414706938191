import React from 'react'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { Link } from 'react-router-dom'
import qs from 'query-string'
import _ from 'lodash'
import { SquareShimmer } from 'components/ShimmerLoader'
import themeable, { themeShape } from 'containers/ThemeManager/Themeable'
import { getAlertLevelColorHML } from 'utils/widgetUtils'
import { SEVERITY_CATEGORIES } from 'appConstants'
import ProgressCircle from '../../components/ProgressCircle'

const RiskScore = ({ value, swimlane, loading, theme, hasProgressCircle }) => {
  if (loading || !value) {
    return (
      <div style={{ margin: 'auto', display: 'inline-block' }}>
        <SquareShimmer size={hasProgressCircle ? 150 : 100} />
      </div>
    )
  }

  const riskScoreNumeric = value.get('riskScoreNumeric') || 100
  let finalValue = _.round(riskScoreNumeric)
  let grade = value.get('riskScore') || 'A'
  let pathColor

  if (grade === 'A') {
    pathColor = theme.success
  } else if (grade === 'B') {
    pathColor = getAlertLevelColorHML(SEVERITY_CATEGORIES.LOW)
  } else if (grade === 'C') {
    pathColor = getAlertLevelColorHML(SEVERITY_CATEGORIES.MEDIUM)
  } else if (grade === 'D') {
    pathColor = '#FF4500' // BLOOORANGE
  } else if (grade === 'F') {
    pathColor = getAlertLevelColorHML(SEVERITY_CATEGORIES.HIGH)
  }

  return (
    <div>
      {!hasProgressCircle ? (
        <div>
          <span
            style={{
              fontSize: '4em',
              color: pathColor,
              display: 'inline-block',
            }}
          >
            {grade}
          </span>
          <br />
          Risk Grade
        </div>
      ) : (
        <div
          style={{
            height: '150px',
            width: '150px',
          }}
        >
          <Link
            to={{
              pathname: '/App/SecurityCenter',
              search: qs.stringify({
                swimlane: swimlane.get('title'),
                status: 'OPEN',
                view: 'resources',
              }),
            }}
            style={{ color: 'inherit', fontWeight: 'normal' }}
            title="View in Security Center"
          >
            <ProgressCircle
              value={finalValue}
              pathColor={pathColor}
              grade={grade}
            />
          </Link>
        </div>
      )}
    </div>
  )
}

RiskScore.propTypes = {
  loading: PropTypes.bool,
  theme: themeShape,
  value: PropTypes.string,
  swimlane: ImmutablePropTypes.map.isRequired,
  hasProgressCircle: PropTypes.bool,
}

export default themeable(RiskScore)
